const Logo = () => {
    return (
        <img
            src={require('../public/images/fpfh_logo.png')}
            className="logo"
            alt="fpfh_logo"
            // width="40px"
        />
    );
};

export default Logo;
